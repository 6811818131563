export function itemsPerRowWidth(
  rowWidth: any,
  itemWidth: any,
  maxColumns: any,
  columnGap: any,
) {
  if (rowWidth !== 0) {
    while (rowWidth < maxColumns * itemWidth + columnGap * (maxColumns - 1)) {
      maxColumns--;
    }
  }

  return maxColumns || 1;
}
export function getMediaQueries({
  maxColumns,
  minColumnWidth,
  maxColumnWidth,
  columnGap,
  ListItemClass,
  gridId,
  isGridMediaQueriesFixEnabled = false,
}: any) {
  let mediaQueries = '';
  while (maxColumns > 0) {
    const minWidth = maxColumns * minColumnWidth + columnGap * (maxColumns - 1);
    mediaQueries = `
${
  isGridMediaQueriesFixEnabled // TODO: get rid of !important and @media when container queries is fully supported
    ? `@container (min-width: ${minWidth}px) {
  #${gridId} .${ListItemClass} {
    -ms-grid-columns: repeat(${maxColumns}, minmax(${minColumnWidth}px, ${maxColumnWidth}))!important;
    grid-template-columns: repeat(${maxColumns}, minmax(${minColumnWidth}px, ${maxColumnWidth}))!important;
  }
}`
    : ''
}
@media (min-width: ${minWidth}px) {
  #${gridId} .${ListItemClass} {
    -ms-grid-columns: repeat(${maxColumns}, minmax(${minColumnWidth}px, ${maxColumnWidth}));
    grid-template-columns: repeat(${maxColumns}, minmax(${minColumnWidth}px, ${maxColumnWidth}));
  }
}
${mediaQueries}`;
    maxColumns--;
  }
  return mediaQueries;
}

export function getGridStyle({
  gridId,
  gridTemplateColumns,
  cssStateDivider,
  rowGap,
  dividerWidth,
  listWrapperClass,
}: any) {
  return `  
#${gridId} .${listWrapperClass} {
  -ms-grid-columns: ${gridTemplateColumns};
}
#${gridId}.${cssStateDivider} {
  padding: calc((${rowGap}px / 2) + ${dividerWidth}) 0;
}
#${gridId}.${cssStateDivider} li::before {
  top: calc((${rowGap}px / -2) - ${dividerWidth});
}
#${gridId}.${cssStateDivider} li::after {
bottom: calc((${rowGap}px / -2) - ${dividerWidth});
}
#${gridId}.${cssStateDivider} li::before,
#${gridId}.${cssStateDivider} li::after,
#${gridId}.${cssStateDivider} .${listWrapperClass}::before,
#${gridId}.${cssStateDivider} .${listWrapperClass}::after {
  height: ${dividerWidth};
}
#${gridId}.${cssStateDivider} li::after,
#${gridId}.${cssStateDivider} li::before {
  left: -${rowGap}px;
}`;
}
