import {
  IHostProps,
  useEnvironment,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { useEffect } from 'react';
import {
  DESKTOP_LAYOUT_MAP_MIN_HEIGHT,
  DESKTOP_WIDGET_MIN_WIDTH,
  MOBILE_LAYOUT_MAP_MIN_HEIGHT,
  MOBILE_WIDGET_MIN_WIDTH,
} from '../../../../../consts';
import { ServiceListLayoutOptions } from '../../../../../types/types';

export type UseWidgetResize = typeof useWidgetResize;

export const useWidgetResize = ({
  resizeComponent,
  serviceListLayout,
}: {
  serviceListLayout: ServiceListLayoutOptions;
  resizeComponent?: IHostProps['resizeComponent'];
}) => {
  const { experiments } = useExperiments();
  const {
    isMobile,
    isEditor,
    isADI,
    dimensions: { width, height },
  } = useEnvironment();

  const isResizeWidgetHeightEnabled = experiments.enabled(
    'specs.bookings.serviceList.resizeWidgetHeight',
  );

  useEffect(() => {
    const widgetMinWidth = isMobile
      ? MOBILE_WIDGET_MIN_WIDTH
      : DESKTOP_WIDGET_MIN_WIDTH;

    const widgetMinHeight = isMobile
      ? MOBILE_LAYOUT_MAP_MIN_HEIGHT[serviceListLayout]
      : DESKTOP_LAYOUT_MAP_MIN_HEIGHT[serviceListLayout];

    if (isEditor && !isADI) {
      const shouldResizeWidget =
        (typeof width === 'number' && width < widgetMinWidth) ||
        (isResizeWidgetHeightEnabled &&
          typeof height === 'number' &&
          height < widgetMinHeight);

      if (shouldResizeWidget && resizeComponent) {
        resizeComponent({
          width: width < widgetMinWidth ? widgetMinWidth : width,
          height:
            isResizeWidgetHeightEnabled && height < widgetMinHeight
              ? widgetMinHeight
              : height,
          mobileResize: isMobile,
        });
      }
    }
  }, [isEditor, isMobile, resizeComponent, width, height, serviceListLayout]);
};
