import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
  createStylesParam,
} from '@wix/tpa-settings';
import { CLASSIC_PRESET_ID, CLASSIC_EDITOR_X_PRESET_ID } from '../../consts';
import { ImageAndTextRatioOptions } from '../../types/types';

export enum IStylesParamsColorsKeys {
  widgetBackgroundColor = 'widgetBackgroundColor', // MULTI_OFFERINGS_BACKGROUND_COLOR
  widgetTitleColor = 'widgetTitleColor', // MULTI_OFFERINGS_TITLE_COLOR
  infoBackgroundColor = 'infoBackgroundColor', // BACKGROUND_COLOR
  borderColor = 'borderColor', // BORDER_COLOR
  filterTitleColor = 'filterTitleColor', // CATEGORY_NAME_COLOR
  filterSelectedIndicationColor = 'filterSelectedIndicationColor', // CATEGORY_SELECTED_UNDERLINE_COLOR
  filterSelectedTextColor = 'filterSelectedTextColor', // CATEGORY_SELECTED_TEXT_COLOR
  filterSelectedBorderColor = 'filterSelectedBorderColor', // CATEGORY_SELECTED_BORDER_COLOR
  filterHoverIndicationColor = 'filterHoverIndicationColor', // CATEGORY_HOVER_BACKGROUND_COLOR
  filterHoverTextColor = 'filterHoverTextColor', // CATEGORY_HOVER_TEXT_COLOR
  filterHoverBorderColor = 'filterHoverBorderColor', // CATEGORY_HOVER_BORDER_COLOR
  filterBorderColor = 'filterBorderColor', // CATEGORY_DIVIDER_COLOR
  filterBackgroundColor = 'filterBackgroundColor', // CATEGORY_BACKGROUND_COLOR
  dropdownTextColor = 'dropdownTextColor', // MOBILE_CATEGORY_NAME_COLOR
  dropdownBorderColor = 'dropdownBorderColor', // MOBILE_CATEGORY_BORDER_COLOR
  dropdownBackgroundColor = 'dropdownBackgroundColor', // MOBILE_CATEGORY_BACKGROUND_COLOR
  serviceImageBackgroundColor = 'serviceImageBackgroundColor', // IMAGE_BACKGROUND_COLOR
  servicesDividerColor = 'servicesDividerColor', // SERVICE_DIVIDER_COLOR
  serviceDetailsColor = 'serviceDetailsColor', // OFFERING_DETAILS_COLOR
  courseAvailabilityColor = 'courseAvailabilityColor', // COURSE_AVAILABILITY_COLOR
  serviceNameColor = 'serviceNameColor', // OFFERING_NAME_COLOR
  serviceTaglineColor = 'serviceTaglineColor', // OFFERING_TAGLINE_COLOR
  moreInfoButtonColor = 'moreInfoButtonColor', // OFFERING_MORE_INFO_LABEL_COLOR
  serviceDividerColor = 'serviceDividerColor', // DIVIDER_COLOR
  bookButtonBackgroundColor = 'bookButtonBackgroundColor', // BUTTON_BACKGROUND_COLOR
  holeBookButtonTextColor = 'holeBookButtonTextColor', // BUTTON_TEXT_COLOR_HOLE
  fillBookButtonTextColor = 'fillBookButtonTextColor', // BUTTON_TEXT_COLOR_FILL
  bookButtonBorderColor = 'bookButtonBorderColor', // BUTTON_BORDER_COLOR
  explorePlansTextColor = 'explorePlansTextColor', // EXPLORE_PLANS_TEXT_COLOR
}

export enum IStylesParamsFontsKeys {
  widgetTitleFont = 'widgetTitleFont', // MULTI_OFFERINGS_TITLE_FONT
  filterTitleFont = 'filterTitleFont', // CATEGORY_NAME_FONT
  dropdownTextFont = 'dropdownTextFont', // MOBILE_CATEGORY_NAME_FONT
  serviceNameFont = 'serviceNameFont', // OFFERING_NAME_FONT
  courseAvailabilityFont = 'courseAvailabilityFont', // COURSE_AVAILABILITY_FONT
  serviceTagLineFont = 'serviceTagLineFont', // OFFERING_TAGLINE_FONT
  moreInfoButtonFont = 'moreInfoButtonFont', // OFFERING_MORE_INFO_LABEL_FONT
  serviceDetailsFont = 'serviceDetailsFont', // OFFERING_DETAILS_FONT
  bookButtonFont = 'bookButtonFont', // BUTTON_TEXT_FONT
  explorePlansFont = 'explorePlansFont', // EXPLORE_PLANS_FONT
}

export enum IStylesParamsNumbersKeys {
  mobileWidgetTitleFontSize = 'mobileWidgetTitleFontSize', // MOBILE_MULTI_OFFERINGS_TITLE_FONT_SIZE
  borderWidth = 'borderWidth', // BORDER_WIDTH
  mobileFilterTitleFontSize = 'mobileFilterTitleFontSize', // MOBILE_CATEGORY_NAME_FONT_SIZE
  filterBorderWidth = 'filterBorderWidth', // CATEGORY_BORDER_WIDTH
  filterBorderRadius = 'filterBorderRadius', // CATEGORY_BORDER_RADIUS
  filterSpacing = 'filterSpacing', // CATEGORY_SPACING
  dropdownBorderWidth = 'dropdownBorderWidth', // MOBILE_CATEGORY_BORDER_WIDTH
  overlappingSidePadding = 'overlappingSidePadding', // OVERLAPPING_SIDE_PADDING
  overlappingVerticalPadding = 'overlappingVerticalPadding', // OVERLAPPING_VERTICAL_PADDING
  stripBorderWidth = 'stripBorderWidth', // STRIP_BORDER_WIDTH
  stripSidePadding = 'stripSidePadding', // STRIP_SIDE_PADDING
  stripVerticalPadding = 'stripVerticalPadding', // STRIP_VERTICAL_PADDING, MOBILE_STRIP_VERTICAL_PADDING
  imageAndTextRatio = 'imageAndTextRatio', // IMAGE_AND_TEXT_RATIO
  mobileServiceNameFontSize = 'mobileServiceNameFontSize', // MOBILE_OFFERING_NAME_FONT_SIZE
  mobileServiceTaglineFontSize = 'mobileServiceTaglineFontSize', // MOBILE_OFFERING_TAGLINE_FONT_SIZE
  mobileMoreInfoButtonFontSize = 'mobileMoreInfoButtonFontSize', // MOBILE_MORE_INFO_LABEL_FONT_SIZE
  serviceDividerWidth = 'serviceDividerWidth', // DIVIDER_WIDTH
  classicSidePadding = 'classicSidePadding', // CLASSIC_SIDE_PADDING, MOBILE_SIDE_PADDING
  classicVerticalPadding = 'classicVerticalPadding', // CLASSIC_VERTICAL_PADDING, MOBILE_VERTICAL_PADDING
  gridSidePadding = 'gridSidePadding', // GRID_SIDE_PADDING, MOBILE_SIDE_PADDING
  gridVerticalPadding = 'gridVerticalPadding', // GRID_VERTICAL_PADDING, MOBILE_VERTICAL_PADDING
  mobileServiceDetailsFontSize = 'mobileServiceDetailsFontSize', // MOBILE_OFFERING_DETAILS_FONT_SIZE
  mobileBookButtonFontSize = 'mobileBookButtonFontSize', // MOBILE_BUTTON_TEXT_FONT_SIZE
  bookButtonBorderWidth = 'bookButtonBorderWidth', // BUTTON_BORDER_WIDTH
  bookButtonCornerRadius = 'bookButtonCornerRadius', // BUTTON_CORNER_RADIUS
  mobileExplorePlansFontSize = 'mobileExplorePlansFontSize', // MOBILE_EXPLORE_PLANS_FONT_SIZE
}

type IStylesParamsColors = {
  [key in IStylesParamsColorsKeys]: StyleParamType.Color;
};

type IStylesParamsFonts = {
  [key in IStylesParamsFontsKeys]: StyleParamType.Font;
};

type IStylesParamsNumbers = {
  [key in IStylesParamsNumbersKeys]: StyleParamType.Number;
};

export type IStylesParams = IStylesParamsColors &
  IStylesParamsFonts &
  IStylesParamsNumbers & {
    presetId: StyleParamType.Number;
  };

const presetId = createStylesParam('presetId', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

const mobileBookButtonFontSize = createStylesParam('mobileBookButtonFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
});

export default createStylesParams<IStylesParams>({
  presetId: presetId as any,
  widgetBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  widgetTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 32, htmlTag: 'h1' }),
  },
  mobileWidgetTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  widgetTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  infoBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  filterTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  filterTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  mobileFilterTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  filterSelectedIndicationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  filterSelectedTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  filterSelectedBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  filterHoverIndicationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  filterHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  filterHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  filterBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  filterBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  filterBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  filterBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  filterSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
  dropdownTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  dropdownBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.4),
  },
  dropdownBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  dropdownBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  dropdownTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  serviceImageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  overlappingSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  overlappingVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  stripBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  stripSidePadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  stripVerticalPadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 32),
  },
  classicSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: (): number => 60,
  },
  classicVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: (): number => 60,
  },
  gridSidePadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 32),
  },
  gridVerticalPadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 32),
  },
  imageAndTextRatio: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) => {
      const preset = getStyleParamValue(presetId);
      switch (preset) {
        case CLASSIC_PRESET_ID:
        case CLASSIC_EDITOR_X_PRESET_ID:
          return Number(ImageAndTextRatioOptions.RATIO_40_60);
        default:
          return Number(ImageAndTextRatioOptions.RATIO_50_50);
      }
    },
  },
  servicesDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  serviceNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 24, htmlTag: 'h2' }),
  },
  serviceNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  mobileServiceNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  serviceTagLineFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16, htmlTag: 'p' }),
  },
  serviceTaglineColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  mobileServiceTaglineFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  moreInfoButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  moreInfoButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  mobileMoreInfoButtonFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  serviceDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: (): number => 1,
  },
  serviceDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  serviceDetailsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  serviceDetailsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16, htmlTag: 'p' }),
  },
  courseAvailabilityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  courseAvailabilityFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16, htmlTag: 'p' }),
  },
  mobileServiceDetailsFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  bookButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  mobileBookButtonFontSize,
  bookButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  holeBookButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  fillBookButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bookButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  bookButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  bookButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  explorePlansTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  explorePlansFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  mobileExplorePlansFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue(mobileBookButtonFontSize) ?? 14,
  },
});
