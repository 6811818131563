import React, { FC, useEffect } from 'react';
import {
  useEnvironment,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { EmptyState } from './EmptyState/EmptyState';
import { Header } from './Header/Header';
import { Body } from './Body/Body';
import { Dialog } from './Dialog/Dialog';
import { HiddenServicesForSeo } from './HiddenServicesForSeo/HiddenServicesForSeo';
import { WidgetViewModelProvider } from './hooks/useWidgetViewModel';
import { WidgetActionsProvider } from './hooks/useWidgetActions';
import { WidgetViewModel } from '../../../viewModel/viewModel';
import { WidgetActions } from '../../../actions/actions';
import { classes, st } from './Widget.st.css';
import { useWidgetResize } from './hooks/useWidgetResize/useWidgetResize';

export type ControllerProps = {
  widgetViewModel: WidgetViewModel;
  widgetActions: WidgetActions;
};

export const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const { widgetViewModel, widgetActions } = props;
  const { headerViewModel, services, seo } = widgetViewModel;
  const { serviceListLayout } = widgetViewModel;
  const { isRTL, isMobile } = useEnvironment();
  const { t } = useTranslation();

  useWidgetResize({
    serviceListLayout,
    resizeComponent: props.host?.resizeComponent,
  });

  useEffect(() => {
    props.widgetActions.getCourseAvailability();
  }, []);

  return (
    <div data-hook="widget" className={st(classes.root, { isRTL, isMobile })}>
      <WidgetViewModelProvider value={widgetViewModel}>
        <WidgetActionsProvider value={widgetActions}>
          {services.length ? (
            <>
              <Dialog
                isOpen={widgetViewModel.showDialog}
                onClose={() => widgetActions.onDialogClose()}
                content={t('user-message.not-bookable')}
                confirmationButtonLabel={t('user-message.action-ok-label')}
              />
              {headerViewModel ? <Header /> : null}
              <Body widgetId={props.id} />
              {seo && seo.shouldListServicesFromOtherCategories ? (
                <HiddenServicesForSeo />
              ) : null}
            </>
          ) : (
            <EmptyState />
          )}
        </WidgetActionsProvider>
      </WidgetViewModelProvider>
    </div>
  );
};
