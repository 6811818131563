import React, { FC, ReactNode } from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa';
import stylesParams from '../../../../../stylesParams';
import { classes, st } from './Details.st.css';
import { DataHooks } from './consts';

export type DetailsProps = {
  children: ReactNode;
  ellipsis?: boolean;
  ariaHidden?: boolean;
  type?: string;
};

export const Details: FC<DetailsProps> = (props) => {
  const { children, type, ariaHidden, ellipsis } = props;
  const { isMobile } = useEnvironment();
  const styles = useStyles();

  return (
    <Text
      data-type={type}
      data-hook={DataHooks.ROOT}
      className={st(classes.root, {
        isMobile,
        ellipsis: ellipsis || false,
      })}
      aria-hidden={ariaHidden || false}
      typography={TYPOGRAPHY.listText}
      tagName={styles.get(stylesParams.serviceDetailsFont).htmlTag}
    >
      {children}
    </Text>
  );
};
