import React, { FC } from 'react';
import {
  Tags,
  TagsSize as SIZE,
  TagsSkin as SKIN,
  TagsAlignment as ALIGNMENT,
} from 'wix-ui-tpa';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { st, classes } from './TagsFilter.st.css';
import { useWidgetActions } from '../../../hooks/useWidgetActions';
import { useWidgetViewModel } from '../../../hooks/useWidgetViewModel';
import settingsParams from '../../../../settingsParams';
import { DataHooks } from '../consts';
import { AlignmentOptions } from '../../../../../../types/types';

const mapAlignmentOptionsToTagsAlignment = {
  [AlignmentOptions.CENTER]: ALIGNMENT.center,
  [AlignmentOptions.LEFT]: ALIGNMENT.left,
  [AlignmentOptions.RIGHT]: ALIGNMENT.right,
};

export const TagsFilter: FC = () => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { filterOptions } = useWidgetViewModel();
  const { onFilterOptionSelected } = useWidgetActions();

  const alignment = settings.get(settingsParams.listFilterAlignment);
  const items = filterOptions.map(({ title, isSelected }, index) => ({
    title,
    checked: isSelected,
    value: `${index}`,
  }));

  return (
    <Tags
      data-hook={DataHooks.Tags}
      className={st(classes.root, { isMobile })}
      skin={SKIN.light}
      size={SIZE.medium}
      alignment={mapAlignmentOptionsToTagsAlignment[alignment]}
      items={items}
      onClick={({ value }) => {
        onFilterOptionSelected(filterOptions[Number(value)]);
      }}
    />
  );
};
